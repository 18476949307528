@import '~styles/mixins';
@import '~styles/variables';


.helloLearnContainer{
    background: $th-white;
    padding: 74px 50px 20px;
    position: relative;
    @include maxWidth1000{
        padding: 70px 50px;
    }
    @include maxWidth766{
        padding: 5px 20px 32px;
    }
}
.helloLeft{
    @include minWidth1000{
        max-width: 635px;
    }
}
.helloText{
    font-family:'Poppins', sans-serif;
    @include font-style(80px,600,#000, $lineHeight: 72px);
    margin-top: 23px;
    margin-bottom: 14px;
    @include maxWidth1000{
        text-align: center;
        line-height: 85px;
    }
    @include maxWidth766{
        font-size: 42px;
        line-height: normal;
        margin-top: 20px;
        margin-bottom: 7px;
    }
    
    .yourText{
        font-weight: 700;
        // background: linear-gradient(94.4deg, #00D4A6 0.87%,  #0F91FF 38.78%,  #CD73FF 56.65%,  #F97691 70.88%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        // animation: hue 3s infinite linear;
    }
    .successText{
        font-size: 120px;
        line-height: 112px;
        // background: linear-gradient(94.4deg, #F97691 0.87%,  #CD73FF 38.78%,  #0F91FF 56.65%,  #00D4A6 70.88%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        // background-clip: text;
        // text-fill-color: transparent;
        // animation: hue 3s infinite linear;
        @include maxWidth766{
            font-size: 60px;
            line-height: normal;
            margin-top: -20px;
            margin-bottom: -6px;
            display: inline-block;
        }
    }
    .madesimple{
        font-size: 62px;
        @include maxWidth766{
            font-size: 42px;
            line-height: 50px;
        }
    }
}
@keyframes hue {
    from {
     filter: hue-rotate(0deg);
    }
    to {
      filter: hue-rotate(-360deg);
    }}
.helloDescription{
    @include font-style(18px,500,rgba(26,26,26,0.8), $lineHeight: 28px);
    font-family:'Poppins', sans-serif;
    letter-spacing: 0.5px;
    @include maxWidth1000{
        text-align: center;
    }
}
.helloWhatsappWrapper {
    @include display-flex($justify: center);
    
    .helloWhatsappButton {
        width: 300px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 50px;
        border-radius: 47px;
        background: #00D656;
        margin-bottom: 20px;
        color: #FFFFFF;
        &:hover {
            color: #FFFFFF;
        }
    }
}
.twoBtnRow{
    display:flex;
    margin-top: 48px;
    @include maxWidth766{
        display: inherit;
    }
}
.helloBtn{
    @include font-style(16px, 600, #000, center,$lineHeight: 24px);
    letter-spacing: 0.5px;
    font-family: 'Roboto', sans-serif;
    width: 196px;
    height: 55px;
    line-height: 55px;
    display: inline-block;
    border: 1px solid #000;
    border-radius: 30px;
    cursor: pointer;
    &:hover{
        font-size: 18px;
        color: $th-white;
        border-color: transparent;
        background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    }
    @include maxWidth766{
        width: 270px;
    }
}
.logo{
    display: flex;
    justify-content: space-between;
    width: 180px;
    padding-left: 12px;
    margin-left: 12px;
    border-left: 2px solid #d1d1d1;
    img{
        width: 180px;
    }
}
.findatutor{
    width: 300px;
    margin-top: 38px;
    @include maxWidth766{
        margin-top: 0;
        width: 270px;
    }
}
.trustRate {
    @include display-flex();
    @include border-style(none, $radius: 0);
    align-items: center;
    padding: 0;
    padding: 6px 16px;
    box-shadow: 0 2px 4px rgba(23,28,52,.16);
    border-radius: 40px;
    max-width: 400px;
    justify-content: center;
    @include maxWidth1000{
        margin: 0 auto 16px;
    }
    & > div {
      min-width: 90px;
    }
    img{
        @include maxWidth766{
            width: 140px;
        }
    }
  }
.ratedLabel{
    font-family:'Poppins', sans-serif;
    @include font-style(16px,500,#000, $lineHeight: 24px);
    letter-spacing: 0.5px;
    @include maxWidth766{
        font-size: 12px;
    }
}
.features {
    @include display-flex($wrap: wrap);
    margin-top: 60px;
    @include maxWidth766{
        display: none;
    }

    .featureItem {
      @include display-flex($align: center);
      margin-right: 16px;
      margin-top: 8px;
      font-family: 'Poppins', sans-serif;
      letter-spacing: 0.5px;
      @include font-style(16px,400,$th-black-A1, $lineHeight: 24px);
      opacity: 0.5;

      div {
        margin-right: 8px;
        height: 24px;

        svg {
          color: $th-cyan;
        }
      }
    }
  }
//   .bottomLinks {
//     @include display-flex();
//     @include border-style(none, $radius: 40px);
//     background: $th-white;
//     padding: 8px 24px;
//     width: 100%;
//     max-width: 540px;
//     box-shadow: 0px 2px 4px rgba(23, 28, 52, 0.16);
//     overflow: hidden;
//     margin-top: 38px;

//     & > div {
//       @include display-flex(center, $align: center);
//       @include border-style($border-right: 1px solid $th-grey2);
//       padding: 0 16px;
//       width: 100%;

//       &:last-child {
//         @include border-style(none);
//       }

//       img {
//         padding-right: 16px;
//       }
//     }
//   }

.freeCoursesImg {
    background: {
        image: url("../../../../static/img/freeCourses.png");
        size: contain;
        position: center;
        repeat: no-repeat;
      };
  }
.topRatesImg {
    background: {
        image: url("../../../../static/img/topRate.png");
        size: contain;
        position: center;
        repeat: no-repeat;
        };
}
.tutorsImg {
    background: {
        image: url("../../../../static/img/tutors.png");
        size: contain;
        position: center;
        repeat: no-repeat;
        };
}
.helloRightWarp{
    position: absolute;
    width: 100%;
    left: 0;
    padding:0 50px;
    @include maxWidth1000{
        position: static;
        padding:50px;
        display: none;
    }
}
.pepoleWarp{
    position: relative;
}
.people{
    width: 100%;
}
.itemWarp{
    display: flex;
    justify-content: flex-end;
}
.circle{
   width: 140%;
   position: absolute;
   animation: rotate 6s ease-in-out infinite alternate;
   top:-36px;
   left: -66%;
}
@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

.tipWarp{
    width: 206px;
    height: 68px;
    padding: 5px 15px;
    border-radius: 7px;
    position: absolute;
    display: flex;
    background: $th-white;
    box-shadow: 0px 17.634748458862305px 47.02599334716797px 0px rgba(0, 0, 0, 0.25);
    @include maxWidth766{
        transform: scale(0.65);
        transform-origin: top right;
    }
    @include maxWidth420{
        transform: scale(0.5);
    }
    .tipLeftImg{
        position: relative;
        width: 47px;
        margin-right: 10px;
    }
    .tipRight{
        flex: 1;
        font-family:'Poppins', sans-serif;
        letter-spacing: 0.58px;
        @include font-style(16px, 600,#52577D, $lineHeight: 32.9px);
        .freeCount{
            color:#EB6F5C ;
        }
        .tutorForYouCount{
            color:#6440FB ;
        }
        .topRateCount{
            color:#00D3A0 ;
        }
        .tipRightText{
            font-weight: 400;
            line-height: normal;
            span{
                font-size: 14px;
                font-family: 'Inter',sans-serif;
            }
        }
    }
}
.free{
    right: 116px;
    top:20px;
    animation: changeFree 6s linear infinite alternate;
}
@keyframes changeFree {
    from {
      right: 116px;
    }
    to {
      right: 60px;
    }
  }
.tutorForYou{
    right: 94%;
    top:46%;
    animation: changeTutorForYou 6s linear infinite alternate;
}
@keyframes changeTutorForYou {
    from {
        top: 46%;
    }
    to {
        top: 26%;
    }
  }
.topRate{
    top: 72%;
    right: 86%;
    animation: changeTopRate 6s linear infinite alternate;
}
@keyframes changeTopRate {
    from {
        top: 72%;
    }
    to {
        top: 86%;
    }
  }


