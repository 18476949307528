@import '~styles/variables';
@import '~styles/mixins';
.courseCarWrap{
    background: #fff;
    padding:14px;
    height: 368px;
    border-radius: 10px;
    cursor: pointer;
    .schoolWarp{
     display: flex;
     align-items: center;
     position: relative;
    }
    .schoolLogo{
        height: 32px;
        width: 32px;
        border-radius: 50%;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        background-color: rgb(5, 100, 255);
        color: #fff;
        img{
            height: 100%;
        }
    }
    .schoolName{
        margin-left: 8px;
        @include font-style(14px,400, $color: #000, $lineHeight:normal);
        font-family:'Roboto', sans-serif;
    }
    .schoolCollect{
       position: absolute;
       right: 0;
    }
    .courseNameWarp{
        height: 180px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        margin:16px 0;
        .mask{
            position: absolute;
            top:0;
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%);
        }
    }
    .courseName{
        position: absolute;
        bottom:7px;
        width: 100%;
        padding-left: 19px;
        @include font-style(16px,600, $color: $th-white, $lineHeight:normal);
        letter-spacing: -0.48px;
        .rating{
            font-size: 11px; 
            letter-spacing: -0.32px;
            line-height: 16px;
            display: flex;
            align-items: baseline;
            img{
                margin-right: 6px;
            }
        }
    }
    .courseOwn{
        display: flex;
        width: 100%;
        position: relative;
        align-items: flex-end;
        .avatar{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 8px;
            line-height: 32px;
            text-align: center;
            background-color: rgb(5, 100, 255);
            color: #fff;
            img{
                height: 100%;
            }
        }
        .role{
            @include font-style(12px,500, $color: #565656, $lineHeight:16px);
        }
        .ownName{
            @include font-style(14px,600, $color: #000, $lineHeight:16px);
        }
        .free{
            position: absolute;
            right: 23px;
            @include font-style(14px,700, $color: #000, $lineHeight:normal);
            font-family:"Inter",sans-serif ;
        }
    }
    .courseShedule{
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 14px;
        .date{
            @include font-style(12px,600, $color: #00D4A6, $lineHeight:normal);
            font-family:"Open Sans" ,sans-serif;
            margin-left: 8px
        }
        .openClass{
            color:#F97691 ;
        }
        .detailBtn{
            display: block;
            height: 32px;
            width: 99px;
            position: absolute;
            right: 0;
            border-radius: 200px;
            background: #000;
            @include font-style(14px,500,#FFF,center,32px);
            letter-spacing: -0.32px;
            cursor: pointer;
        }
    }

}