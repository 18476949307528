@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include border-style(none, $radius: 20px);
  @include display-flex(flex-start, flex-start, $direction: column);
  min-width: 443px;
  min-height: 325px;
  background: $th-white;
  box-shadow: 0px 2px 16px rgba(98, 104, 110, 0.181518);
  padding: 40px;
  margin:12px;
  font-family: 'Roboto', sans-serif;


  .header {
    @include display-flex(flex-start, center);
    .img {
      @include font-style(24px, 400, $th-white, center);
      @include display-flex(center, center);
      @include square(36px);
      padding-top: 2px;
      background: #0087D0;
      border-radius: 18px;
      margin-right: 10px;
    }

    .name {
      @include font-style(24px, 600, #000,$lineHeight:28px);
    }
  }

  .subtitle {
    @include display-flex($direction: column);
    padding-left: 43px;
    .rating {
      @include display-flex();
      span {
        padding-left: 10px;
        @include font-style(16px, 600, $th-grey1,$lineHeight:19px);
      }
    }
    .positive {
      @include font-style(16px, 600, $th-black,$lineHeight:19px);
      span {
        @include font-style(16px, 400, $th-grey1,$lineHeight:19px);
      }
    }
  }

  .description {
    @include font-style(16px, 400, #000,$lineHeight:19px);
    padding: 16px 0 0 43px;

    a {
      padding-left: 4px;
      color: $th-purple;
      z-index: 10;
    }
  }
}


@media screen and (max-width: 800px) {
  .wrapper {
    min-width: 320px;
    .subtitle {
      padding-left: 0;
    }

    .description {
      padding-left: 0;
    }
  }
}
