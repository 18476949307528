@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  padding: 162px 104px 113px;
  background: $th-white;

  & > section {
    padding: 0;
  }
}
.container{
    width: 100%;
    margin: 0 auto;
    max-width: 1396px;
}

.faqHeader {
  @include font-style(40px, 700, #000000,center, $lineHeight: 60px);
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 56px;
}


.accordionWrapper {
  padding-top: 0;
}

.accordionTitle {
  background: linear-gradient(98.79deg, rgba(0, 212, 166, 0.08) 0.49%, rgba(15, 145, 255, 0.08) 50.58%, rgba(205, 115, 255, 0.08) 97.93%)!important;
}

.accordionBlock {
  background: $th-white!important;
}

.faqAccordion {
  margin-top: 30px;
}

.contactBlock {
  border-radius: 10px;
  background: $th-light-purple;
  padding: 40px 60px;
  margin-top: 8px;

  p, a {
    @include font-style(16px, 400, $th-purple, center, $lineHeight: 21px);
    margin: 0;
  }

  a:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 767px) {
 .wrapper{
  padding: 52px 10px 0;
 }
  .faqHeader {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 22px;
}

  .contactBlock {
    padding: 20px;
  }
}
