@import '~styles/mixins';

.body {
  @include transform(translate(0px, 200px));
  @include transition(all 700ms ease-out);
  opacity: 0;
  backface-visibility: hidden;
}

.opacity {
  opacity: 1;
  @include transform(translate(0px, 0))
}

.inView.body {
  @include transform(translate(0px, 0px));
  opacity: 1;
}
