@import '~styles/variables';
@import '~styles/mixins';

.Trustpress-slider {
  padding: 0 15px;
  .slick-dots {
    bottom: -59px;
    @include maxWidth766{
      bottom: -40px;
    }
    li {
      button {
        &:hover {
          &:before {
            color: #000;
            opacity: 0.6;
          }
        }
        &:before {
          font-size: 20px;
          width: 16px;
          height: 16px;
          color: #E5DFE9;
          opacity: 1;
        }
      }
    }
    li.slick-active {
      width: 70px;
      button {
        &:before {
          content: ' ';
          background: #000;
          width: 64px;
          height: 17px;
          border-radius: 20px;
          opacity: 1;
        }
      }
    }
  }

  .slick-slider {
    @include display-flex(center);
    .slick-list {
      padding: 0 !important;
    }
  }

  &:hover {
    .slick-arrow {

      &.slick-prev,
      &.slick-next {
        display: block!important;
      }
    }
  }

  .slick-arrow {
    width: 16px;
    height: 100%;
    z-index: 400;

    &.slick-next {
      @include position(absolute, $right: 40px, $z-index: 1);
      display: block!important;
    }

    &.slick-prev {
      @include position(absolute, $left: 40px, $z-index: 1);
      display: block!important;

      &:before {
        @include transform(rotateZ(180deg));
      }
    }
    &:before {
      @include background-config(no-repeat, cover);
      content: ''; // ../../../../static/img/home/pressSlider/arrowReview.svg
      // background-image: url(../../../static//img//home//pressSlider/arrowReview.svg);
      width: 16px;
      height: 33px;
      display: block;
      opacity: 1;
      z-index: 300;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
}
