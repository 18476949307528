@import '~styles/variables';
@import '~styles/mixins';

div.ukant-question-list {
  div.ant-collapse-item,
  div.ant-collapse-item:last-child {
    @include border-style(none, $radius: 10px);
    margin-bottom: 26px;
    background: white;

    .ant-collapse-header {
      @include font-style(18px, 600, $th-black-A1, $lineHeight:normal);
      padding: 16px ;

      &[aria-expanded="true"] i {
        @include font-style(14px, 900, $th-black);
        transform: rotate(0deg) translate(-8px, -9px);
      }

      &[aria-expanded="false"] i {
        @include font-style(14px, 900, $th-black);
        transform: rotate(-90deg) translate(5px, -50%);
      }
    }

    .ant-collapse-content-box {
      @include font-style(16px, normal, $th-dark-grey, $lineHeight: 21px);
    }
  }
}
@media screen and (max-width: 767px) {
  div.ukant-question-list {
    div.ant-collapse-item,
    div.ant-collapse-item:last-child {
      margin-bottom: 10px;
  
      .ant-collapse-header {
        font-size: 14px;
        line-height: 21px;
        padding-right: 18px;
      }
    }
  }
}

