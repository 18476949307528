@import '~styles/variables';
@import '~styles/mixins';

.heroWrap {
  @include position(relative);
  width: 100%;
  padding:70px 72px 100px;
  font-family:'Inter', sans-serif;
  @include maxWidth1000{
    padding: 70px 50px 100px;
    flex-direction: column;
  }
  @include maxWidth766{
    padding: 35px 20px;
  }
  .headerLink {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    padding-bottom: 32px;
  
    a {
      color: #000000;
  
      &:hover {
        color: #40A9FF;
      }
    }
  }
  .visitour{
    @include font-style(20px,600,#000,center, $lineHeight:normal);
    margin-bottom: 90px;
    @include maxWidth766{
      margin-bottom:40px;
    }
  }
  .mainContentWarp{
    justify-content: space-between;
    @include display-flex();
    @include maxWidth1000{
      flex-direction: column;
    }
  }
  
  .leftWarp{
    max-width:920px ;
    .heroText{
      @include font-style(62px,600,#000, $lineHeight:normal);
      margin-bottom: 24px;
      @include maxWidth1000{
        font-size: 48px;
      }
      @include maxWidth766{
        font-size: 26px;
      }
      
    }
    .heroDescr{
      max-width: 855px;
      @include font-style(35px,400,#000, $lineHeight:normal);
      @include maxWidth766{
        font-size: 18px;
      }
    }
    .heroLinks{
      padding: 0 38px;
      height: 51px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 60px 0 80px 0;
      border-radius: 40px;
      background: $th-light-cyan;
      @include font-style(24px,500,#fff, $lineHeight:normal);
      &.signUpFreeButton {
        margin: 40px 0 80px 0;
        border-radius: 47px;
        background:$th-light-cyan;
        font-size: 29px;
      }
      &:hover{
        color: #fff;
      }
      @include maxWidth1000{
        margin: 40px 0 50px 0;
      }
      @include maxWidth766{
        font-size: 20px;
        margin: 30px 0 40px 0;
      }
    }
    .features{
      .featureItem{
        display: flex;
        align-items: center;
        span{
          margin:0 12px;
          @include font-style(24px,400,#000, $lineHeight:normal);
          @include maxWidth766{
            font-size: 18px;
            line-height: 28px;
          }
        }
        svg {
          color: $th-light-cyan;
        }
      }
    }
  }
  .contentRight{
    width: 622px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    @include maxWidth1000{
      margin-top: 40px;
      width: 100%;
      justify-content: center;
    }
    .contentRightPic{
      position: absolute;
      top:-45px;
      width: 94%;
      @include minWidth1600{
        width: 70%;
      }
      @include maxWidth1000{
        position: static;
        width: 70%;
      }
    }
    .red{
      width: 80%;
    }
    .peopleWarp{
      margin-top: -58%;
     
      .people{
        position: relative;
        .circleLine{
          width: 100%;
        }
        .peopleImg{
          width: 95%;
          position: absolute;
          left: 50%;
          top:50%;
          transform: translate(-50%,-50%);
        }
        .green{
          position: absolute;
          bottom: -14%;
          left: 40%;
          width: 50%;
        }
      }
    }
  
  }
}

