@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex(center, center, $direction: column);
  padding: 64px 0 185px;
  overflow: hidden;
  background: #fff;
  @include maxWidth766{
    padding: 40px 0 70px;
  }

  .sliderWrapper {
    @include position(relative);
    max-width: 100%;

    &:before {
      content: '';
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      height: 100%;
      width: 100%;
      pointer-events: none;
      // background: -moz-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // background: -webkit-gradient(linear, left top, right top, color-stop(0%, $th-light-purple), color-stop(19%, rgba(255, 255, 255, 0)), color-stop(81%, rgba(255, 255, 255, 0)), color-stop(100%, $th-light-purple));
      // background: -webkit-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // background: -o-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // background: -ms-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // background: linear-gradient(to right, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
    }
    .preArrow{
      @include transform(rotateZ(180deg));
      margin-right: 24px;
      cursor: pointer;
    }
    .nextArrow{
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .titleBlock {
    @include display-flex(center, center, $direction: column);

    .logo {
      @include display-flex(space-between);
      width: 190px;
    }

    .title {
      @include font-style(32px, 500, #62686E,$lineHeight:normal);
      font-family: 'Roboto', sans-serif;
      margin-bottom: 40px;
      @include maxWidth766{
        @include font-style(18px);
      }
    }
  }

  .slider {
    max-width: 1700px;
  }
}

