@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include position(relative);
  padding: 146px  0 136px;
  background-color: #fff;
  @include maxWidth766{
    padding: 60px 0;
    &:before {
      display: none;
    }
  }
}
.priceMobile {
  display: none;
}

.topTutorsTitle {
  @include font-style(32px, 900,$th-black, center, normal);
  font-family: 'Roboto', sans-serif;
}

.subjectsTitle {
  @include font-style(24px, 600, $th-purple, $lineHeight: 28px);
  margin-bottom: 28px;
  font-style: italic;
}

.subjectsList {
  @include position(relative, $z-index: 1);
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.subjectsListItem {
  display: inline-block;
  margin: 0 16px 16px 0;
  border: 1px solid $th-purple;
  border-radius: 10px;
  font-style: italic;

  a {
    display: block;
    color: $th-purple;
    padding: 4px 18px;
    font-size: 16px;
  }

  &:hover {
    background-color: $th-purple;
    a {
      color: $th-white;
    }
  }

  &Active {
    background-color: $th-purple;
    a {
      color: $th-white;
    }
  }
}

.topTutorList {
  @include display-flex(center);
}

.topTutorsLink {
  @include display-flex(center);
  padding-top: 24px;

  a {
    @include display-flex(center, center);
    @include font-style(18px, 900, #fff, center, normal);
    font-family: 'Roboto', sans-serif;
    border-radius: 50px;
    background: #000;
    border: 1px solid #000;
    width: 280px;
    height: 53px;
    &:hover{
        font-size: 18px;
        color: $th-white;
        border-color: transparent;
        background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    }
  }
}

.topTutorItem {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 24px 46px 24px 80px;
  background-color: $th-white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 320px;
  margin: 60px 0 0 -100px;
  font-family: 'Roboto', sans-serif;
  .rating{
    text-align: center;
  }

  &:first-child {
    margin-left: 0;
  }

  &Image {
    @include square(80px);
    @include border-style(none, $radius: 50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    margin: -64px auto 0;
  }

  &Name {
    @include font-style(16px, normal, $th-black, center, 21px);
  }
  &Descr {
    margin: 16px 0;
    @include font-style(16px, 600, $th-black, center, 19px);
  }
  &Price {
    @include font-style(16px, 600, $th-middle-gray, center, 19px);
    span {
      @include font-style(32px, 900, $th-black, center, 38px);
    }
    margin-top: 4px;
  }
  &Lessons {
    // @include display-flex(center, center);
    color: $th-middle-gray;
    margin: 16px 0 6px;
    font-size: 14px;
    line-height: 16px;
    text-align: end;
  }
  &Experience {
    display: flex;
    @include font-style(14px, 600, $th-middle-gray, center, 21px);
    span{
      margin-left: 10px;
    }
  }
}

.topTutorItemPrice {
  @include display-flex(center, center);
}


@media screen and (max-width: 767px) {

  .topTutorsTitle {
    font-size: 24px;
    line-height: 28px;
    font-style: normal;
    text-align: start;
  }

  .hasMobilePrice {
    display: none;
  }
  .priceMobile {
    display: block;
  }

  .topTutorList{
   overflow-y: scroll;
   &::-webkit-scrollbar{
    display: none;
   }
  }
  .topTutorItem{
    min-width: 320px;
    &:first-child {
      margin-left: 800px;
    }
  }

.topTutorsLink{
  padding-top: 43px;
}
  .subjectsListItem {
    margin: 0 8px 16px 0;
  }
}
