@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex(center,flex-end);
  overflow: hidden;
  background: linear-gradient(93.11deg, #00D4A6 0.63%, #0F91FF 51.13%, #CD73FF 98.88%);
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.rightSide {
  max-width: 725px;
  padding: 100px 0 100px 67px;
}

.title {
  @include font-style(40px, 700, $th-white,$lineHeight:60px);
  margin-bottom: 20px;
  font-family: 'Poppins',sans-serif;
}

.description{
  margin-bottom: 27px;
  span {
    @include font-style(18px, 500, $th-white, $lineHeight: 28px);
    font-family: 'Poppins',sans-serif;
    letter-spacing: 0.5px;
    display: inline;
  }

}


.startedBtn {
  width: 240px;
  height: 55px;
  background:  #000;
  border: none;
  border-radius: 200px;
  @include font-style(16px, 600, $th-white, $lineHeight: 24px);
  letter-spacing: 0.5px;
  font-family: 'Roboto', sans-serif;
x

  &:hover {
    background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
  }
}

@media screen and (max-width: 1100px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
  .wrapper {
    flex-direction: column;
    align-items: center;
    padding: 47px 10px 72px;
    background: linear-gradient(167.39deg, #01D4A6 3.66%, #228FFF 38.96%, #CE74FF 100%);

  }

  .leftSide {
    text-align: center;
    margin-bottom: 32px;

    img {
      max-width: 320px;
      width: 100%;
    }
  }

  .rightSide {
    width: 100%;
    @include display-flex($align: center, $direction: column);
    padding: 0 30px;
  }

  .description span {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
  }

  .startedBtn {
    margin-top: 8px;
  }

  .title {
    // text-align: center;
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 8px;
  }
}
