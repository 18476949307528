@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  background: linear-gradient(98.79deg, rgba(0, 212, 166, 0.08) 0.49%, rgba(15, 145, 255, 0.08) 50.58%, rgba(205, 115, 255, 0.08) 97.93%);
  padding-top: 123px;
  padding-bottom: 119px;
}
.container{
    width: 100%;
    margin: 0 auto;
    max-width: 1380px;
}
.title {
  @include font-style(40px, 700, #000, center, 55px);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.description {
  @include font-style(18px, 400, $th-dark-gray, center, 23px);
  font-family: 'Poppins', sans-serif;
}

.steps {
  @include display-flex(space-between);
  margin-top: 46px;
}

.step {
  text-align: center;
  width: 360px;
  height: 170px;
  background-color: #fff;
  border-radius: 20px;
  margin: 0 50px;
  display: flex;
  padding: 49px 66px;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 100%;
    height: 64px;
  }

  .stepTitle {
    @include font-style(18px, 600, $th-black-A1,$lineHeight:24px);
    font-family: 'Poppins', sans-serif;
    margin-left: 23px;
  }
}

@media screen and (max-width: 1050px) {
  .title {
     font-size: 24px;
    // text-decoration: underline;
    text-align: start;
    margin-bottom: 0;
  }
  .description{
    text-align: start;
    font-size: 16px;
  }
  .wrapper {
    padding:24px 16px;
  }

  .steps {
    @include display-flex($direction: column);
    align-items: center;
    margin-top: 0;
  }

  .step {
    width: 100%;
    max-width: 360px;
    margin: 8px 0;
    padding:49px 36px;
  }
}
