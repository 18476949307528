@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
    padding: 0 86px 213px;
    margin-top: 133px;
    font-family:'Inter', sans-serif;
    @include maxWidth1000{
      padding: 0px 40px 100px;
      margin-top: 100px;
    }
    @include maxWidth766{
      padding: 0px 20px 60px;
      margin-top: 40px;
    }
}
.textWarp{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.onlyAccept{
  @include font-style(64px,600,#000,center, $lineHeight:normal);
  max-width: 866px;
  @include maxWidth1000{
    font-size: 48px;
  }
  @include maxWidth766{
    font-size:26px;
  }
}
.ourOline{
  @include font-style(32px,500,#000,center, $lineHeight:normal);
  max-width: 668px;
  margin-top: 45px;
  margin-bottom:63px ;
  @include maxWidth1000{
  margin-bottom:63px ;
  }
  @include maxWidth766{
    font-size:22px;
    margin-top: 38px;
    margin-bottom:56px ;
  }
}
.findTutor{
  width: 238px;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $th-light-cyan;
  border-radius: 47px;
  @include font-style(29px,500,#fff,center, $lineHeight:normal);
  @include maxWidth1000{
    font-size:26px;
  }
  @include maxWidth766{
    font-size:22px;
  }
  &:hover{
    color: #fff;
  }
}
.picsWarp{
  margin-top: 157px;
  display: flex;
  justify-content: space-between;
  @include maxWidth1000{
    margin-top: 100px;
  }
  @include maxWidth766{
    margin-top: 57px;
    flex-direction: column;
  }
}
.getStartedNowContainer {
  @include display-flex($direction: column, $justify: center);
  align-items: center;
  margin-top: 120px;

  .preTitle {
    @include font-style(64px, 500, #000, center, normal);
    @include maxWidth766{
      font-size: 24px;
    }
  }
  .title {
    @include font-style(96px, 600, #000, center, normal);
    margin-bottom: 18px;
    @include maxWidth766{
      font-size: 36px;
    }
  }
  .description {
    @include font-style(32px, 500, #000, center, normal);
    width: 1100px;
    
    @include maxWidth766{
      width: 300px;
      font-size: 20px;
    }
  }
  .signUpButton{
    width: 300px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 47px;
    background:$th-light-cyan;
    margin-top: 40px;
    @include font-style(29px,500,#fff,center, $lineHeight:normal);
    @include maxWidth766{
      font-size:22px;
    }
    &:hover{
      color: #fff;
    }
  }

  @include maxWidth766{
    margin-top: 0px;
  }
}
.itemWarpUae {
  border-radius: 15px;
  border: 2px solid #000;
}
.itemWarp{
  width: 469px;
  
  @include maxWidth766{
    width: 100%;
    margin-bottom: 40px;
  }
  &:nth-child(1){
    background-color:#000 ;
  }
  &:nth-child(2){
    margin: 0 40px;
    background-color:#008848 ;
    @include maxWidth1000{
      margin: 0 20px;
    }
    @include maxWidth766{
      margin: 0 0 40px 0;
    }
  }
  &:nth-child(3){
    background-color:#DF504A ;
  }
}
.itemWarpCN{
  width: 469px;
  
  @include maxWidth766{
    width: 100%;
    margin-bottom: 40px;
  }
  &:nth-child(1){
    background-color:#D0AD50;
  }
  &:nth-child(2){
    margin: 0 40px;
    background-color:#D0AD50;
    @include maxWidth1000{
      margin: 0 20px;
    }
    @include maxWidth766{
      margin: 0 0 40px 0;
    }
  }
  &:nth-child(3){
    background-color:#D0AD50;
  }
}
.itemImg{
  img{
    width: 100%;
    height: 200px;
  }
}
.itemHeader{
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-style(32px,500,#fff,center, $lineHeight:normal);
  @include maxWidth1000{
    font-size: 18px;
    height: 58px;
   }
  @include maxWidth766{
   font-size: 24px;
   height: 68px;
  }
}


