@import '~styles/variables';
@import '~styles/mixins';

.classSujectContainer{
    background: $th-white;
    padding-top: 170px;
    padding-bottom: 160px;

}
.sectionTitle{
    margin-bottom: 69px;
    @include font-style(40px,700, #000,$align:center, $lineHeight:60px);
    font-family: 'Poppins', sans-serif;
}
.sectionWarp{
    overflow: hidden;
}
.categaryItem{
    margin-bottom: 38px;
    height: 208px;
}
.categary{
    @include font-style(20px,600, $color:$th-black-A1,$lineHeight:normal);
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 7px;
    img{
        margin-right: 10px;
    }
}
.subjectItem{
    li{
        list-style-type: none;
        &::marker{
            color: #3B8BFF;
            font-size: 10px;
        }
    }
}
.subjectName{
    @include font-style(14px,600, $color:#3B8BFF,$lineHeight:21px);
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.5px;
    margin-left: -6px;
}
.line{
    margin: 0 12px;
}

@media screen and (max-width: 992px) {
    .classSujectContainer{
        padding-top: 34px;
        padding-bottom: 34px;
    }
    .sectionWarp{
        height: 620px;
        overflow-y: scroll;
        &::-webkit-scrollbar{
        display: none;
        }
    }
    .sectionTitle{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 36px;
        text-align: start;
    }
    .categaryItem{
    height: initial;
    margin-bottom: 28px;
  }
}