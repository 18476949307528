@import '~styles/variables';
@import '~styles/mixins';

.video{
  &Wrapper {
    @include position(relative);
    display: inline-block;
    width: 100%;
    line-height: 1;

    video {
      width: 100%;
      height: 100%;
      margin: -1px;
      cursor: pointer;
    }

    .playpause {
      @include position(absolute, 0, 0, 0, 0, 3);
      @include background-config(no-repeat, contain, center);
      @include square(68px);
      background: url('../../static/img/play.svg');
      margin: auto;
      cursor: pointer;
    }

    .preview {
      @include position(absolute, 0);
      height: unset;
    }
  }
}
