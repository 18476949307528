@import '~styles/mixins';
@import '~styles/variables';


.exploreContainer{
    padding: 49px 0 142px;
    background: linear-gradient(98.79deg, rgba(0, 212, 166, 0.08) 0.49%, rgba(15, 145, 255, 0.08) 50.58%, rgba(205, 115, 255, 0.08) 97.93%);
    display: flex;
    justify-content: center;
    @include maxWidth1000{
      padding: 49px 70px 60px;
      justify-content:unset;
    }
    @include maxWidth766{
      padding: 24px 18px 40px;
    }
  }
.titleHeader {
  @include display-flex(flex-start, center);
  margin-bottom: 49px;
  @include maxWidth1000{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }
  .findTextIcon{
    @include maxWidth1000{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .tabsWarp{
    @include maxWidth1000{
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .exploreText{
      font-family:'Poppins', sans-serif;
      @include font-style(40px,700, $color:#000, $lineHeight:94px);
      margin-right: 21px;
      @include maxWidth766{
        font-size: 32px;
        line-height: normal;
      }
  }
  

  .btnFilled {
    @include font-style(24px, 600, #000, center, normal);
    letter-spacing: 0.5px;
    font-family: 'Poppins',sans-serif;
    @include border-style(none, $radius: 10px);
    background: none;
    padding: 12px 19px;
    cursor: pointer;
    @include maxWidth1000{
      width: 48%;
      background: rgba(0,0,0,0.08);
      border-radius: 10px;
    }
    &.active{
      background: #000;
      color: white;
    }
  }
}
.findContent{
  display: flex;
  @include maxWidth1000{
    width: 100%;
    position: relative;
  }
}
.tutorCardWarp{
  width: 944px;
  @include maxWidth1000{
    width: 100%;
  }
}
.courseCardWarp{
  width: 1008px;
  @include maxWidth1000{
    width: 100%;
  }
}
.spinLoading{
  text-align: center;
  margin-top: 120px;
}
.mobileFilterIcon{
  display: none;
  @include maxWidth1000{
    display: flex;
    align-items: center;
  }
  span{
    @include font-style(20px, 500, #000,$lineHeight:52px);
    letter-spacing: 0.5px;
    font-family: 'Poppins',sans-serif;
    background: linear-gradient(94.4deg, #00d4a6 0.87%, #0f91ff 38.78%, #cd73ff 56.65%, #f97691 70.88%), #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  img{
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
}
.mobileFilter{
  display: none;
  @include maxWidth1000{
    display: block;
    position: absolute;
    z-index: 20;
    background: rgba(0,0,0,0.7);
    width: 100%;
    padding: 20px 20px 50px;
    border-radius: 10px;
    min-height: 300px;
  }
}
.deskFilter{
  padding-top: 120px;
  display: flex;
  margin-left: 52px;
  width: 230px;
  &.tutorDeskFilter{
    width: 134px;
  }
  @include maxWidth1000{
    display: none;
  }
}
.searchWarp{
  // @include display-flex(space-between,center);
}
.search{
  height: 55px;
  input{
    border-radius: 8px;
  }
  :global{
    .ant-input-suffix{
      .anticon-search{
        display: none;
      }
    }
  }
}
.searchResults::-webkit-scrollbar{
  display: none;
}
.searchResults{
  max-height: 764px;
  overflow-y: scroll;
  @include maxWidth1000{
    min-height: 300px;
  }
}

.itemCol{
  // width: 462px;
  // margin-right: 22px;
  // &:last-child{
  //   margin-right: 0;
  // }
}
.loadMore{
  padding-left: 46.5%;
}
.noTutors{
  @include font-style(16px, 400,#80868B, $lineHeight:21px);
  padding: 0 14px;
}

