@import '~styles/variables';
@import '~styles/mixins';

.homepage-logo-press-slider {
  padding: 0 50px;

  .slick-arrow {
    width: 16px;
    height: 100%;

    &.slick-next {
      @include position(absolute, $right: 0, $z-index: 1);
    }

    &.slick-prev {
      @include position(absolute, $left: 0, $z-index: 1);

      &:before {
        @include transform(rotateZ(180deg));
      }
    }
    &:before {
      @include background-config(no-repeat, cover);
      content: '';
      background-image: url(../../../../static/img/home/pressSlider/ukarrowReview.svg);
      width: 10px;
      height: 20px;
      display: block!important;
      opacity: 1;
    }

    &.slick-disabled {
      display: block !important;
      &::before{
        opacity: 1;
      }
    }
  }
}
