@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex(flex-start, flex-start, $direction: column);
  min-width: 443px;
  min-height: 332px;
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(98, 104, 110, 0.181518);
  border-radius: 20px;
  padding: 40px;
  margin:12px;


  .header {
    @include display-flex(flex-start, center);
    .img {
      @include font-style(24px, 400, $th-white, center);
      font-family: 'Roboto', sans-serif;
      @include display-flex(center, center);
      @include square(36px);
      padding-top: 2px;
      background: #EE4178;
      border-radius: 18px;
      margin-right: 10px;
    }

    .name {
      @include font-style(24px, 600, #000,$lineHeight:28px);
      font-family:'Roboto', sans-serif
    }
  }

  .subtitle {
    @include display-flex($direction: column);
    padding-left: 43px;
    padding-top: 10px;
    .rating {
      @include display-flex();
      span {
        padding-left: 10px;
        @include font-style(16px, 400, #8F8F8F,$lineHeight:19px);
        font-family:'Roboto', sans-serif
      }
    }
    .subtitleText {
      @include font-style(16px, 600, $th-black,$lineHeight:19px);
      font-family:'Roboto', sans-serif;
      span {
        @include font-style(16px, 400, $th-grey1);
      }
    }
  }

  .description {
    @include font-style(16px, 400, #000,$lineHeight:19px);
    padding: 16px 0 0 43px;

    a {
      padding-left: 4px;
      color: $th-purple;
      z-index: 10;
    }
  }
}


@media screen and (max-width: 800px) {
  .wrapper {
    // width: 100%;
    // height: auto;
    min-width: 280px;
    .subtitle {
      padding-left: 0;
    }

    .description {
      padding-left: 0;
    }
  }
}
