@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex($align: center);
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.benefitDescription {
  b {
    color: $th-dark-grey;
  }
}

.benefitsH2 {
  @include font-style(40px, 700, #000, center, 60px);
  font-family:'Poppins', sans-serif;
  margin-bottom: 37px;
}

.benefit {
    max-width: 360px;
    margin: 0 auto;
  &List {
    @include display-flex(space-between, $wrap: wrap);
    max-width: 1360px;
    margin: 0 auto 212px;
  }

  &Header {
    @include display-flex($align: center);
    @include font-style(18px, 600, $th-black-A1, $lineHeight: 24px);
    font-family: 'Poppins', sans-serif;
    margin-bottom: 23px;
    height: 24px;

    img {
      padding-right: 10px;
    }
  }

  &Description {
    @include font-style(16px, 400, $th-grey1, $lineHeight: 24px);
    font-family: 'Poppins', sans-serif;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    @include display-flex($wrap: wrap,$align:start);
    padding: 0 30px 20px;
  }
  .benefit{
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
.benefit{
    &List {
        margin-bottom: 0;
    }
}

  .benefitsH2 {
    font-size: 24px;
    line-height: 36px;
  }
}
