@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex(center, center);
  background: #fff;
  padding: 149px 0;

  .videoBlock {
    margin-right: 24px;
    width: 90%;
    max-width: 784px;
    max-height: 328px;
    border-radius: 20px;
    overflow: hidden;
  }
  .videoPlayer{

  }

  .infoBlock {
    max-width: 359px;
    .info {
      @include font-style(32px, 900, #000, center);
      font-family: 'Poppins', sans-serif;
      padding-bottom: 24px;

      span {
        @include font-style($color:#3C8AFF);
      }
    }

    .btn {
      border-radius: 50px;
      height: 56px;
      background: #000;
      border:#000;
      a {
        @include font-style(18px,500,$color: $th-white,$lineHeight:21px);
        font-family: 'Roboto', sans-serif;
      }
      &:hover{
        border-color: transparent;
        background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
    }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding: 32px 0 54px;
    @include display-flex($direction: column);
    .videoBlock {
      width: 85%;
      margin-right: 0;
    }

    .infoBlock {
      .info {
        @include font-style(24px,$align:center,$lineHeight:36px);
        max-width: 265px;
        padding-top: 24px;
      }
    }
  }
}