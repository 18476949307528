@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  @include display-flex(center, center, $direction: column);
  padding: 118px 0 191px;
  overflow: hidden;
  // background: linear-gradient(135deg, rgba(0, 212, 166, 0.08) 0%, rgba(15, 145, 255, 0.08) 51.40%, rgba(205, 115, 255, 0.08) 100%);
  background: linear-gradient(98.79deg, rgba(0, 212, 166, 0.08) 0.49%, rgba(15, 145, 255, 0.08) 50.58%, rgba(205, 115, 255, 0.08) 97.93%);

  .sliderWrapper {
    @include position(relative);
    max-width: 100%;

    &:before {
      content: '';
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      height: 100%;
      width: 100%;
      pointer-events: none;
      background: -webkit-gradient(linear,left top,right top,from(#f8f4fb),color-stop(19%,hsla(0,0%,100%,0)),color-stop(81%,hsla(0,0%,100%,0)),to(#f8f4fb));
      background: linear-gradient(90deg, rgba(0, 212, 166, 0.08) 0%,hsla(0,0%,100%,0) 30%,hsla(0,0%,100%,0) 70%,#f8f4fb);
      // background: -moz-linear-gradient(left, rgba(0, 212, 166, 0.08) 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      /* FF3.6+ */
      // background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(0, 212, 166, 0.08)), color-stop(30%, rgba(255, 255, 255, 0)), color-stop(70%, rgba(255, 255, 255, 0)), color-stop(100%, $th-light-purple));
      // /* Chrome,Safari4+ */
      // background: -webkit-linear-gradient(left, rgba(0, 212, 166, 0.08) 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // /* Chrome10+,Safari5.1+ */
      // background: -o-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      // /* Opera 11.10+ */
      // background: -ms-linear-gradient(left, $th-light-purple 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      /* IE10+ */
      // background: linear-gradient(to right, rgba(0, 212, 166, 0.08) 0%, rgba(255, 255, 255, 0) 19%,rgba(255, 255, 255, 0) 81%, $th-light-purple 100%);
      /* IE6-9 */
      // background: linear-gradient(98.79deg, #00d4a6 0, rgba(15, 145, 255, 0) 50.58%, $th-light-purple 97.93%);
    }
    .preArrow{
      @include transform(rotateZ(180deg));
      margin-right: 24px;
      cursor: pointer;
    }
    .nextArrow{
      margin-left: 24px;
      cursor: pointer;
    }
  }

  .titleBlock {
    @include display-flex(center, center, $direction: column);

    .logo {
      // @include display-flex(space-between);
      // width: 190px;
    }

    .title {
      @include font-style(32px, 900, $th-middle-gray);
      margin-bottom: 40px;
    }
  }

  // .slider {
  //   max-width: 1700px;
  // }
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding: 40px 0;
    .titleBlock {
      .title {
        @include font-style(18px);
      }
    }
    .sliderWrapper {
    &:before {
      background:none;
    }
   }
  }

}
