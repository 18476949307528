@import '~styles/variables';
@import '~styles/mixins';

.title {
  @include font-style(32px, 600, $th-cyan, center, 42px);
  margin-bottom: 24px;
}

.questionList {
  @include border-style(none, $radius: 0 50px);
  width: 100%;
  padding: 24px;

  &.noPadding {
    padding: 0;
  }

  & > div {
    background: transparent;
  }

  .paragraph {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .title {
    @include font-style(24px, 400, $th-cyan, center, 33px);
    margin-bottom: 10px;
  }

  .wrapper {
    padding-top: 40px;
    // background: $th-light-purple;
  }

  .questionList {
    padding: 8px 24px 40px;
  }
}
