@import '~styles/variables';
@import '~styles/mixins';

.container {
  margin: 16px 0 25px;
  min-width: 232px;

  & .ant-form-item {
    margin-bottom: 0 !important;
    width: 100%;
  }

}
.radioStyle{
  display: block;
  height: 28px;
  line-height: 28px;
  @include font-style(14px, 400,#000,$lineHeight:24px);
  font-family: 'Inter',sans-serif;
  @include maxWidth1000{
    color: #fff;
  }
}
.categoryRadioWarp{
  margin-bottom: 14px;
  .radioStyle{
    @include font-style(16px, 600,#000,$lineHeight:normal);
    font-family: 'Poppins',sans-serif;
    @include maxWidth1000{
      color: #fff;
    }
  }
}
.moreSubject{
  @include display-flex(center, center);
  border-radius: 200px;
  background: #000;
  border: 1px solid #000;
  width: 178px;
  height: 48px;
  margin-top: 50px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-family: 'Roboto',sans-serif;
  @include maxWidth1000{
    color: #000;
    background: #fff;
  }
  &:hover{
    font-size: 18px;
    color: #ffffff;
    border-color: transparent;
    background: linear-gradient(93.03deg, #00D4A6 3.09%, #0F91FF 39.56%, #CD73FF 79.05%, #F97691 98.43%);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  }
}
.topTitle{
  @include font-style(24px, 600,#000);
  font-family: 'Poppins',sans-serif;
  background: linear-gradient(94.4deg,#00d4a6 .87%,#0f91ff 38.78%,#cd73ff 56.65%,#f97691 70.88%),#000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.subTitle{
  @include font-style(16px, 600, $th-black-A1, $lineHeight: normal);
  font-family: 'Poppins', sans-serif;
  margin-top: 8px;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include maxWidth1000{
    color: #fff;
    width: 120px;
  }
  img{
    margin-right: 5px;
  }
}




.filterText{
  @include font-style(24px, 600, #000, $lineHeight: 42px);
  font-family: 'Poppins', sans-serif;
}
.filterTipText{
  @include font-style(16px, 400, #070707, $lineHeight: 36px);
}
.selectWarp{
  :global {
    .ant-select-selection--single{
      height: 55px;
      border-radius: 10px;
    }
    .ant-select-selection__rendered{
      line-height: 55px;
      position: relative;
    }
    .ant-select-selection__placeholder{
      position: absolute;
      top: 36px;
    }
    .ant-select-selection-selected-value{
      @include font-style(16px, 500, $th-black-A1,$lineHeight:24px);
      font-family: 'Roboto', sans-serif;
      position: absolute;
      top: 24px;
    }
    .ant-select-search{
      .ant-select-search__field{
        position: absolute;
        top:10px;
      }
    }
  }
}
.filterBlock {
  padding: 7px 0;
  position: relative;
}

.filterLabel {
  @include font-style(12px, 400, $th-black-A1, $lineHeight: 24px);
  font-family: 'Roboto', sans-serif;
  position: absolute;
  z-index:1;
  left: 11px;
  top: 12px;
}



@media screen and (max-width: 767px) {
 .container{
  min-width:auto
 }  
}




