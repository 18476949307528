@import '~styles/variables';
@import '~styles/mixins';

.mobile {
  display: none;
}

.wrapper {
  padding: 21px 0;
  background: linear-gradient(135deg, rgba(0, 212, 166, 0.08) 0%, rgba(15, 145, 255, 0.08) 51.40%, rgba(205, 115, 255, 0.08) 100%);
}

.sliderItem {
  @include display-flex(center, center);
  height: 24px;

  img {
    max-width: 100%;
    max-height: 100%;
    @include transition(0.4s all);
    filter: grayscale(0);

    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 767px) {
.wrapper{
  padding: 21px 0;
}

}
