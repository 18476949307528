@import '~styles/variables';
@import '~styles/mixins';

.wrapper {
  font-family:'Inter', sans-serif;
}
.showcase {
  @include display-flex($direction: row);
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  
  margin-top: -200px;
  @include maxWidth1000{
    margin-top: -75px;
  }
  @include maxWidth766{
    margin-top: 0px;
  }

  .showcaseItem {
    @include font-style(42px, 600,#000, center, $lineHeight:normal);
    padding: 0px 5%;

    @media screen and (max-width: 767px) {
      font-size: 34px;
    }
  }
}
.featureWarp{
  display: flex;
  justify-content: space-between;
  max-width: 1270px;
  margin: 0 auto;
  margin-bottom: 130px;
  @include maxWidth1400{
    display:block;
  }
}
.features{
  .featureItem{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .featuresText{
      flex:1;
    }
    span{
      margin:0 12px;
      @include font-style(24px,400,#000, $lineHeight:normal);
      @include maxWidth766{
        font-size: 18px;
        line-height: 28px;
      }
    }
    svg {
      color: $th-light-cyan;
    }
    .spanAr {
      font-size: 32px !important;
    }
  }
}
.container{
  width: 100%;
   padding: 0 107px;
   @include maxWidth1000{
    padding: 100px 40px;
  }
  @include maxWidth766{
    padding: 40px 20px;
  }
  .visitour{
    @include font-style(24px, 600,#000,center, $lineHeight:normal);
    margin-bottom: 90px;
    @include maxWidth766{
      margin-bottom:40px;
    }
  }
}
.title {
  @include font-style(64px, 600, #000, center, normal);
  margin-bottom: 18px;
  @include maxWidth1000{
    font-size: 48px;
  }
  @include maxWidth766{
    font-size: 26px;
  }
  
}
.popularSubjectsContainer {
  @include display-flex($justify:center, $direction: column);
  align-items: center;

  .linksContainer {
    @include display-flex($justify: center, $direction: column);
    align-items: center;
  
    .linksBar {
      @include display-flex($justify: center);
      flex-wrap: wrap;
      margin-top: 20px;
      margin-bottom: 40px;
    
      a {
        font-size: 28px;
        margin-left: 15px;
        margin-right: 15px;
        color: #000000;
        font-weight: 500;
    
        // &:hover {
        //   color: #1890ff;
        // }
      }
    
      @include maxWidth766{
        width: 330px;
      }
    }
  
    .findTutor{
      width: 238px;
      height: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 47px;
      background:$th-light-cyan;
      margin-bottom: 120px;
      @include font-style(29px,500,#fff,center, $lineHeight:normal);
      @include maxWidth1000{
        font-size:26px;
      }
      @include maxWidth766{
        font-size:22px;
      }
      &:hover{
        color: #fff;
      }
    }
  }
}
.description{
  margin-bottom: 168px;
  @include maxWidth1000{
    margin-bottom: 100px;
  }
  @include maxWidth766{
    margin-bottom: 40px;
  }
}
.Personalised{
  @include font-style(22px, 400, #000, center, normal);
  margin-top: 18px;
  margin-bottom: 102px;
  @include maxWidth1000{
    margin-bottom: 60px;
  }
  @include maxWidth766{
    font-size: 18px;
    margin-bottom: 18px;
  }
}
.improveyour{
  @include font-style(32px, 500, #000, center, normal);
  max-width: 687px;
  margin: 0 auto;
  @include maxWidth1000{
    font-size: 24px;
  }
  @include maxWidth766{
    font-size: 22px;
  }
}

.steps {
  @include display-flex(space-between);
  align-items: flex-start;
  max-width: 80%;
  margin: 0 auto;
  @include maxWidth1000{
    max-width:none;
  }
  @include maxWidth766{
    flex-direction: column;
    align-items: center;
  }
}

.step {
  @include display-flex();
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  @include maxWidth1000{
    width: 210px;
  }
  @include maxWidth766{
    margin-bottom: 40px;
  }
  img {
    width: 108px;
    @include maxWidth766{
      width: 88px;
    }
  }

  .stepTitle {
    margin-top: 56px;
    @include font-style(32px, 500, #000,center,  $lineHeight:normal);
    @include maxWidth1000{
      margin-top: 40px;
      font-size: 28px;
    }
    @include maxWidth766{
      margin-top: 20px;
      font-size: 22px;
    }
  }
}
.twoPeople{
  margin-top: 179px;
  @include maxWidth1000{
    margin-top: 120px;
  }
  @include maxWidth1000{
    margin-top: 80px;
  }
  @include maxWidth766{
    margin-top: 20px;
  }
  img{
    width: 100%;
  }
  .cnPeopleRow {
    @include display-flex($justify: space-between, $align: center, $wrap: nowrap);
    .cnPeopleLeftColumn {
      @include display-flex($direction: column);
      .cnPeopleSubtitle {
        @include font-style(22px, 400, #000, left, normal);
        margin-top: 6px;
        margin-left: 20px;
        @include maxWidth766{
          font-size: 18px;
        }
      }
      .cnPeopleTitle {
        @include font-style(42px, 500, #000, left, normal);
        margin-bottom: 8px;
        font-family: 'Times New Roman', Times, serif;
        @include maxWidth1000{
          font-size: 24px;
        }
        @include maxWidth766{
          font-size: 22px;
        }
      }
    }
    img {
      width: 40%;
    }
  }
}

