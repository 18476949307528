@import '~styles/_variables';
@import '~styles/mixins';
.homeContainer {
  // background-color: #E9EFF7;
  @include maxWidth766{
    overflow-x: hidden;
  }
}
.uaeHomeContainer {
  background-color: #fff;
}
.uaeHowitworkTopTutorsSubjectsWarp{
  position: relative;
}
.findTutor{
  display: flex;
  width: 280px;
  height: 55px;
  background-color: #0077FF;
  border-radius: 24px;
  @include font-style(18px, 600, $th-white, $lineHeight:55px);
  font-family: 'Roboto',sans-serif;
  letter-spacing: 0.5px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  span{
    margin-left: 7px;
  }
}
